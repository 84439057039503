<template>
  <DialogFormWrapper>
    <template #form>
      <v-form
        id="generateKpoCardForm"
        ref="generateKpoCardForm"
        v-model="isFormValid"
        class="custom-form pt-8"
        @submit.prevent="submitKpoCardForm"
      >
        <v-row>
          <v-col cols="12">
            <v-autocomplete
              :items="debrisTypes"
              v-model="kpoCard.debrisTypeId"
              v-bind="getAutocompleteAttrs('Typ odpadu','Wybierz typ odpadu')"
              :item-text="getDebrisString"
              :filter="searchDebrisType"
            />
          </v-col>

          <v-col cols="12">
            <v-autocomplete
              :items="vehicles"
              v-model="kpoCard.vehicleId"
              v-bind="getAutocompleteAttrs('Pojazd','Wybierz pojazd')"
              item-text="parsedData"
            />
          </v-col>

          <v-col cols="12">
            <v-autocomplete
              :items="businessPlaces"
              v-model="kpoCard.receiverId"
              v-bind="getAutocompleteAttrs('Miejsce prowadzenia działalności','Wybierz miejsce prowadzenia działalności')"
              @change="getCommunes"
            />
          </v-col>

          <v-col cols="12">
            <v-autocomplete
              :items="communes"
              v-model="kpoCard.communeId"
              validate-on-blur
              v-bind="getAutocompleteAttrs('Gmina','Wybierz gminę')"
            />
          </v-col>

          <v-col cols="12">
            <DateWithTime
              label="Planowany czas transportu"
              :date-with-time="kpoCard.plannedTransportTime"
              @changeTime="kpoCard.plannedTransportTime = $event"
            />
          </v-col>

          <v-col cols="12">
            <v-textarea
              outlined
              :rows="3"
              auto-grow
              label="Uwagi do karty"
              v-model="kpoCard.additionalInfo"
              placeholder="Wpisz dodatkowe informacje"
            />
          </v-col>
        </v-row>
      </v-form>
    </template>
    <template #submit>
      <v-btn
        color="primary"
        class="base-hover"
        :loading="isProcessing"
        type="submit"
        form="generateKpoCardForm"
      >
        Wygeneruj kartę
      </v-btn>
    </template>
  </DialogFormWrapper>
</template>

<script>
import rules from '../../utils/validators'
import { searchDebrisType, getDebrisString } from '../../utils'
import DialogFormWrapper from './Partials/DialogFormWrapper'
import DateWithTime from './Inputs/DateWithTime.vue'
import { mapState, mapActions } from 'vuex'

export default {
  components: {
    DialogFormWrapper,
    DateWithTime,
  },
  data () {
    return {
      isFormValid: true,
      kpoCard: {
        orderId: null,
        debrisTypeId: null,
        receiverId: null,
        communeId: null,
        vehicleId: null,
        plannedTransportTime: new Date().toISOString(),
        additionalInfo: null,
      }
    }
  },
  computed: {
    ...mapState({
      course: state => state.layout.dialog.item,
      isProcessing: state => state.courses.isProcessing,
      debrisTypes: state => state.debrisTypes.items,
      vehicles: state => state.vehicles.items,
      communes: state => state.communes.items,
      drivers: state => state.drivers.items,
      businessPlaces: state => state.businessPlaces.items,
    })
  },
  mounted () {
    this.getBusinessPlaces()

    const { order } = this.course.swapCourse || this.course
    this.kpoCard.orderId = order.id

    const vehicleId = this.drivers.find(driver => driver.id === this.course.driver?.id)?.vehicle?.id
    if (vehicleId) this.kpoCard.vehicleId = vehicleId
  },
  methods: {
    ...mapActions({
      closeDialog: 'layout/closeDialog',
      generateKpoCard: 'courses/generateKpoCard',
      getBusinessPlaces: 'businessPlaces/getItems',
      getCommunes: function (dispatch, businessPlaceId) {
        return dispatch('communes/getItems', {
          params: { businessPlaceId }
        })
      },
    }),
    searchDebrisType,
    getDebrisString,
    getAutocompleteAttrs (label, placeholder) {
      return {
        label,
        placeholder,
        dense: true,
        outlined: true,
        itemText: 'name',
        itemValue: 'id',
        rules: [rules.required],
      }
    },
    submitKpoCardForm () {
      if (this.$refs.generateKpoCardForm.validate()) {
        this.generateKpoCard(this.kpoCard)
          .then(() => { this.closeDialog() })
      }
    }
  }
}
</script>
